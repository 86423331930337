<template>
  <c-box w="full">
    <Breadcrumb :items="breadcrumbs" />
    <hr v-chakra mt="20px" />
    <c-text
      mt="30px"
      font-family="Montserrat"
      font-size="24px"
      font-weight="700"
      line-height="36px"
    >
      Tambah Notifikasi
    </c-text>
    <FormCreate v-chakra mt="30px" v-model="notifikasi" />
    <c-stack mt="30px" is-inline spacing="20px">
      <c-button
        rounded="full"
        h="62px"
        w="289px"
        as="router-link"
        :to="{ name: 'admin.notifikasi' }"
      >
        Batal
      </c-button>
      <c-button
        variant-color="brand"
        rounded="full"
        h="62px"
        w="289px"
        @click.prevent="submit()"
      >
        Simpan
      </c-button>
    </c-stack>
  </c-box>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import FormCreate from "@/views/admin/notifications/form-create";
import Breadcrumb from "@/components/breadcrumb";
import "vue-popperjs/dist/vue-popper.min.css";

export default {
  name: "SUNotifikasiEdit",
  components: { FormCreate, Breadcrumb },
  data() {
    return {
      value: null,
    };
  },
  validations: {
    name: { required },
    type: { required },
    content: { required },
    target: { required },
    dateTime: { required: requiredIf((it) => it.type === "scheduler") },
  },
  computed: {
    notifikasiId() {
      return this.$route.params.notifikasiId;
    },
    breadcrumbs() {
      return [
        {
          label: "Manajemen Notifikasi",
          href: this.$router.resolve({ name: "admin.notifikasi" }).href,
        },
        { label: "Tambah Notifikasi", isCurrent: true },
      ];
    },
    notifikasi: {
      get() {
        return {
          name: this.value?.name,
          content: this.value?.content,
          type: this.value?.type,
          target: this.value?.targetRole,
          dateTime: this.value?.sendAt,
        };
      },
      set(v) {
        this.value ??= {};
        this.value.name = v.name;
        this.value.content = v.content;
        this.value.type = v.type;
        this.value.targetRole = v.target;
        this.value.sendAt = v.dateTime;
      },
    },
  },
  watch: {
    notifikasiId: {
      immediate: true,
      async handler(id) {
        this.value = await this.$store.dispatch(
          "admNotifikasi/getNotifikasiById",
          id
        );
      },
    },
  },
  methods: {
    submit() {
      this.$store.dispatch("admNotifikasi/edit", this.value)
      .then(() => {
        this.$router.replace({
          name: "admin.notifikasi-detail",
          params: { notifikasiId: this.value.id },
        })
      }).catch((e) => {
        this.$toast({
          position: "bottom-left",
          variant: "left-accent",
          title: e.toString(),
        })
      })
    },
  },
};
</script>

<style scoped></style>
